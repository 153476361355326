import type { NextPage } from "next";
import LoginContainer from "../../containers/auth/login";
import { WithNonMobileAppLayout } from "../../containers/layout";

const Login: NextPage = () => {

  return (<>
    <WithNonMobileAppLayout>
      <LoginContainer />
    </WithNonMobileAppLayout>
  </>);
};

export default Login;
